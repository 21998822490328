<template>
  <tab-container>
    <div slot="operation" :class="$style.updateText">
      <a-icon type="sync" :spin="refreshFlag" :class="$style.refreshIcon" />
      {{ $t('detail.lastUpdateTime') }}：{{
        formateTime(liveData.lastUpdateTime)
      }}
    </div>
    <div :class="$style.baseInfo">
      <div :class="$style.infoItem">
        <a-col
          :span="12"
          v-for="(item, key) in baseInfoText"
          :key="key"
          :class="$style.item"
        >
          <div :class="$style.info">
            <div :class="$style.label">
              {{ item }}
            </div>
            <div>{{ baseInfoList[key] }}</div>
          </div>
        </a-col>
      </div>
    </div>
  </tab-container>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceLiveData } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import { swindDirection } from '@/views/screen/utils';

@Component({
  components: {
    TabContainer,
  },
})
export default class LiveData extends Vue {
  @Prop({ type: String, default: undefined }) deviceId;
  mounted() {
    this.getData();
  }
  liveData = {};
  async getData() {
    this.liveData =
      (await deviceLiveData(this.deviceId, deviceTypeEnum.DUST.deviceGroup)) ||
      {};
  }
  get baseInfoList() {
    return {
      'PM2.5': this.liveData.pm25 >= 0 ? this.liveData.pm25 + 'µg/m³' : '--',
      'PM10': this.liveData.pm10 >= 0 ? this.liveData.pm10 + 'µg/m³' : '--',
      'Temperature':
        this.liveData.temperature >= 0 ? this.liveData.temperature + '℃' : '--',
      'Humidity':
        this.liveData.humidity >= 0 ? this.liveData.humidity + '%' : '--',
      'WindDirection':
        this.liveData.windDirection >= 0
          ? swindDirection(this.liveData.windDirection)
          : '--',
      'WindSpeed':
        this.liveData.windSpeed >= 0 ? this.liveData.windSpeed + 'm/s' : '--',
      'Noise': this.liveData.noise >= 0 ? this.liveData.noise + 'dB' : '--',
      'Pressure':
        this.liveData.pressure >= 0 ? this.liveData.pressure + 'Pa' : '--',
      'TSP': this.liveData.tsp >= 0 ? this.liveData.tsp + 'µg/m³' : '--',
    };
  }
  baseInfoText = {
    'PM2.5': 'PM2.5',
    'PM10': 'PM10',
    'Temperature': this.$t('safety.temperature'),
    'Humidity': this.$t('greenConstruction.humidity'),
    'WindDirection': this.$t('greenConstruction.windDirection'),
    'WindSpeed': this.$t('detail.windspeed'),
    'Noise': this.$t('greenConstruction.noise'),
    'Pressure': '气压',
    'TSP': 'TSP',
  };
  refreshFlag = false;
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '--';
  }
}
</script>

<style lang="less" module>
.updateText {
  color: #999999;
  .refreshIcon {
    margin-right: 2px;
  }
}
.baseInfo {
  .infoItem {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .item {
      padding: 0 0 5px 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--border);
      .info {
        .label {
          color: var(--font-info);
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
