<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item label="PM10预警阈值" prop="pm10Warn">
          <div :class="$style.formItem">
            <a-input-number
              :min="0"
              :formatter="limitDecimalPoint"
              placeholder="请输入PM10预警阈值"
              v-model="form.pm10Warn"
              :class="$style.numberInput"
            />
            <span :class="$style.unit">μm/m³</span>
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="PM10报警阈值" prop="pm10Error">
          <div :class="$style.formItem">
            <a-input-number
              :min="0"
              :formatter="limitDecimalPoint"
              placeholder="请输入PM10报警阈值"
              v-model="form.pm10Error"
              :class="$style.numberInput"
            />
            <span :class="$style.unit">μm/m³</span>
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item label="PM2.5预警阈值" prop="pm25Warn">
          <div :class="$style.formItem">
            <a-input-number
              :min="0"
              :formatter="limitDecimalPoint"
              placeholder="请输入PM2.5预警阈值"
              v-model="form.pm25Warn"
              :class="$style.numberInput"
            />
            <span :class="$style.unit">μm/m³</span>
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="PM2.5报警阈值" prop="pm25Error">
          <div :class="$style.formItem">
            <a-input-number
              :min="0"
              :formatter="limitDecimalPoint"
              placeholder="请输入PM2.5报警阈值"
              v-model="form.pm25Error"
              :class="$style.numberInput"
            />
            <span :class="$style.unit">μm/m³</span>
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-model-item label="警报联动喷淋条件">
          <a-checkbox v-model="form.sprayLinkage">
            数据报警触发喷淋
          </a-checkbox>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  dustThresholdSetting,
  getDustThreshold,
} from '@/services/device-manage/setting';

/* 设置限制小数位数(小数点后面两位数)*/
const limitDecimalPoint = value => {
  const reg = /^(-)*(\d+)\.(\d).*$/;
  return `${value}`.replace(reg, '$1$2.$3');
};
@Component
export default class SettingForm extends Vue {
  @Prop({ type: String, default: () => {} }) deviceId;

  limitDecimalPoint = limitDecimalPoint;
  form = {
    idxDeviceId: '',
    pm10Error: '',
    pm10Warn: '',
    pm25Error: '',
    pm25Warn: '',
    sprayLinkage: true,
  };
  rules = {
    pm10Error: [
      {
        required: true,
        message: 'PM10报警阈值不能为空',
      },
    ],
    pm10Warn: [
      {
        required: true,
        message: 'PM10预警阈值不能为空',
      },
    ],
    pm25Error: [
      {
        required: true,
        message: 'PM2.5报警阈值不能为空',
      },
    ],
    pm25Warn: [
      {
        required: true,
        message: 'PM2.5预警阈值不能为空',
      },
    ],
  };
  mounted() {
    this.initFrom();
  }
  async initFrom() {
    try {
      const data = await getDustThreshold(this.deviceId);
      this.form = data;
    } catch {
      return false;
    }
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (this.form.pm10Warn > this.form.pm10Error) {
          this.$message.error('PM10预警阈值不可大于PM10报警阈值');
          valid = false;
        }
        if (this.form.pm25Warn > this.form.pm25Error) {
          this.$message.error('PM2.5预警阈值不可大于PM2.5报警阈值');
          valid = false;
        }
        if (valid) {
          await this.$refs.form.validate();
          this.form.idxDeviceId = this.deviceId;
          try {
            await dustThresholdSetting(this.form);
            this.$message.success('设置成功');
            resolve(true);
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.formItem {
  display: flex;
  justify-content: space-between;
  .numberInput {
    width: 86%;
  }
  .unit {
    color: var(--font);
  }
}
</style>
